
export interface RequestInterface
{
    url     : string;
    method  : string;
    header  : any;
    body    : any;
    [key: string]: any;
}

export const RequestInitial = {
    url     : '',
    method  : 'GET',
    header  : '',
    body    : '',
}

export const RequestHeaderInitial = {
    'Content-type' : 'application/json'
}

/**
 * 送信リクエスト管理サービス
 * 
 * 
 */
export default class RequestService
{
    // サーバーリクエストに必要なすべての情報を格納
    private request: RequestInterface = RequestInitial;
    // JSON文字列に変換したBodyデータを格納
    private _body: string = '';
    // Headerオブジェクトに変換したHeaderデータを格納
    private _header: {[key: string] : string} = RequestHeaderInitial;

    private _csrf: string = '';
    private _bearer: string = '';

    /**
     * サーバーリクエスト情報を設定する
     * 
     * @param request RequestInterface
     * @return RequestService
     */
    public setRequest(request: any): RequestService
    {
        for (const key in this.request) {
            if (Object.prototype.hasOwnProperty.call(this.request, key)) {
                this.request[key] = request[key];
            }
        }
        return this;
    }

    /**
     * URL情報を設定
     * 
     * @param url string
     * @return RequestService
     */
    public setURL(url :string): RequestService
    {
        this.request.url = url;
        return this;
    }

    /**
     * Method情報を設定
     * 
     * @param method string
     * @return RequestService
     */
    public setMethod(method: string): RequestService
    {
        this.request.method = method;
        return this;
    }

    /**
     * Header情報を設定
     * textareaの改行コードを含んだ文字列
     * 配列、オブジェクト型どちらの形式でも対応
     * 
     * @param header string | object
     * @return RequestService
     */
    public setHeader(header: any): RequestService
    {
        this.request.header = header;
        return this;
    }

    /**
     * トークンを設定
     * @param type string ['csrf', 'bearer']
     * @param token string
     * @returns RequestService
     */
    public setToken(type: string, token: string): RequestService
    {
        if (type === 'csrf') {
            this._csrf = token;
        } else if (type === 'bearer') {
            this._bearer = token;
        }
        return this;
    }

    /**
     * Body情報を設定
     * textareaの改行コードを含んだ文字列
     * 配列、オブジェクト型どちらの形式でも対応
     * 
     * @param body string | object
     * @return RequestService
     */
    public setBody(body: any): RequestService
    {
        this.request.body = body;
        return this;
    }

    /**
     * 設定された全てのリクエストデータを返す
     * 
     * @return RequestInterface
     */
    public getAllRequest(): object
    {
        return this.request;
    }

    /**
     * 送信用に変換されたBodyデータを返す
     * ※ 原則JSON文字列
     * 
     * @return string JSON
     */
    public getBuildBody(): string
    {
        return this._body;
    }

    /**
     * 送信用に変換されたHeaderデータを返す
     * 
     * @return object
     */
    public getBuildHeader(): object
    {
        return this._header;
    }

    /**
     * リクエストデータをイニシャルデータで上書き
     * 
     * @return RequestService
     */
    public reset(): RequestService
    {
        this.request = RequestInitial;
        return this;
    }

    /**
     * ヘッダー、送信データをHttpオプション形式に変換
     * 
     * @return RequestService
     */
    public buildRequestParam(): RequestService
    {
        // ヘッダーを送信用に整形
        this.buildHeader();
        // ボディを送信用に整形
        this.buildSendDataToJson();
        
        if (this.request.method === 'GET') {
            this._body = this.jsonToURLParam(this._body);
        }

        return this;
    }

    /**
     * 送信オプションの作成
     * 
     * @return object
     */
    public buildOption(): object
    {
        const option: Option = {
            method: this.request.method,
            headers: this._header,
        };
        if (this.request.method !== 'GET') {
            option.body = this._body
        }
        return option;
    }

    /**
     * URLをメソッドに合わせて変換
     * 
     * @return string
     */
    public buildURL(): string
    {
        if (this.request.method === 'GET') {
            return this.request.url + this._body;
        }
        return this.request.url;
    }

    /**
     * JSONデータをURLリクエストに変換
     * 
     * @param json Json文字列
     */
    private jsonToURLParam(json: string): string
    {
        if (json.length < 5 || json === undefined) {
            return '';
        }
        const _json = JSON.parse(json);
        let param = '?';
        for (const key in _json) {
            if (_json.hasOwnProperty(key)) {
                param = param + key + '=' + _json[key] + '&';
            }
        }
        return param;
    }

    /**
     * フォームのデータをJSONに変換
     */
    private buildSendDataToJson(): void
    {
        const ch = Object.prototype.toString;
        const w = ch.call(this.request.body).slice(8, -1).toLowerCase()
        let pdata = this.request.body;
        if (w !== 'array' && w !== 'object') {
            pdata = this.parseFactory('body')
        }
        this._body = JSON.stringify(pdata);
    }

    /**
     * Body、Header情報を所定のオブジェクト形式に変換
     * 
     * @param target string
     * @return any
     */
    private parseFactory(target: string): any
    {
        if (this.request[target] === '') {
            return {};
        }

        const delimita = (target === 'header') ? ':' : '=';
        const factoryObj: any = {};
        let _obj: any;

        const ch = Object.prototype.toString;
        const w = ch.call(this.request[target]).slice(8, -1).toLowerCase()
        // console.log(target);
        // console.log(this.request);
        // 配列・オブジェクト以外の場合は改行で分割
        if (w === 'array' || w === 'object') {
            _obj = this.request[target];
        } else {
            _obj = this.request[target].split(/\n/);
        }

        for (const key in _obj) {
            if (_obj.hasOwnProperty(key)) {
                const val = _obj[key].split(delimita);
                factoryObj[val[0]] = val[1];
            }
        }
        return factoryObj;
    }

    /**
     * ヘッダー配列からHttpHeadrsオブジェクトを作成
     */
    private buildHeader(): void
    {
        if (this.request.header !== '') {
            this.resetHeaders();
            this._header = this.parseFactory('header');
        }
        this.atachToken();
    }

    /**
     * トークンが設定されている場合に、トークン情報を追加
     * @returns boolean
     */
    private atachToken(): boolean
    {
        if (this._csrf === '' && this._bearer === '') {
            return false;
        }
        
        if (this._csrf !== '') {
            this._header['X-XSRF-TOKEN'] = this._csrf;
        }
        if (this._bearer !== '') {
            this._header['Authorization'] = 'Bearer ' + this._bearer;
        }
        return true;
    }

    /**
     * 変換済みヘッダー情報をイニシャル値に戻す
     */
    private resetHeaders(): void
    {
        this._header = RequestHeaderInitial;
    }
}


interface Option
{
    method: string;
    headers: {[key: string] : string};
    [prop: string]: any;
}