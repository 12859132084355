import pdfMake from 'pdfmake/build/pdfmake';
import { StandardMakerService } from './maker/standard-maker.service';
import { SvgMakerService } from './maker/svg-maker.service';
import { PrintLayoutService } from './layout/print-layout.service';
import { SheetSize } from'./layout/standardSize.helper';

export class PDFBuilderService {

    private StandardObj : any = null;
    private SvgObj      : any = null;

    private outputLayout: any;

    public callStandardMaker(): StandardMakerService
    {
        if (!this.StandardObj) {
            this.StandardObj = new StandardMakerService()
        }
        return this.StandardObj;
    }

    public callSvgMaker(): SvgMakerService
    {
        if (!this.SvgObj) {
            this.SvgObj = new SvgMakerService();
        }
        return this.SvgObj;
    }

    /**
     * 印刷レイアウトオブジェクトを作成
     * @param layout any
     * @param papername 
     * @param orientation 
     * @returns PdfBuilderService
     */
    public async buildLayout(
        layout: any,
        papername: SheetSize = 'A4',
        orientation: 'portrait' | 'landscape' = 'portrait'
    ): Promise<PDFBuilderService> {
        const pls = new PrintLayoutService();
        console.log(layout);
        this.outputLayout = await pls.setSheetStandard(
                                papername,
                                orientation
                            )
                            .makePdfLayout(layout);
        return this;
    }

    /**
     * 印刷レイアウトオブジェクトを作成
     * @param layout any
     * @param papername 
     * @param orientation 
     * @returns PdfBuilderService
     */
    public async buildLayoutToSvg(
        layout: any,
        papername: SheetSize = 'A4',
        orientation: 'portrait' | 'landscape' = 'portrait'
    ): Promise<PDFBuilderService> {
        const pls = new PrintLayoutService();
        this.outputLayout = await pls.setSheetStandard(
                                papername,
                                orientation
                            )
                            .makePdfLayoutBySVG(layout);
        return this;
    }


    /**
     * レイアウト情報からPDF作成
     * PDFダウンロードイベント発生
     * @param docDefinition any レイアウト情報
     * @return PDFデータ返却
     */
    public buildPDF(name: string = 'export'): any {
        this.outputLayout.defaultStyle = this.outputLayout.defaultStyle || {};
        const pdf = pdfMake.createPdf(this.outputLayout);
        return pdf.download(name + '.pdf');
    }

    /**
     * レイアウト情報からPDF作成
     * PDFダウンロード後印刷プロンプト表示
     * @param docDefinition any レイアウト情報
     */
    public printPDF() {
        this.outputLayout.defaultStyle = this.outputLayout.defaultStyle || {};
        pdfMake.createPdf(this.outputLayout).print();
    }
}

