
import { FileService } from './file.service';

export class DragService
{
    private static instance: DragService;

    private target: any;
    private binary: boolean = true;
    private drag: string = 'drag';
    private onDrag: boolean = false;

    private FS: FileService;

    public constructor()
    {
        this.FS = new FileService();
    }

    public static call(): DragService
    {
        if (!DragService.instance) {
            DragService.instance = new DragService();
        }
        return DragService.instance;
    }

    /**
     * ファイルの読み込みモードを変える
     * @ true(デフォルト) バイナリデータ読み込み
     * @ false テキストデータ読み込み
     * @param mode boolean 
     * @returns 
     */
    public setMode(mode: boolean): DragService
    {
        this.binary = mode;
        return this;
    }

    public setFileGetOption(drag: 'drag' | 'select' = 'drag'): DragService
    {
        this.drag = drag;
        return this;
    }

    /**
     * イベントを監視するタグを指定
     * @param target string タグのID
     * @returns DragService
     */
    public setTarget(target: string): DragService
    {
        this.reset();
        this.target = document.querySelector('#' + target) as HTMLElement;
        return this;
    }

    /**
     * ターゲットタグにイベントリスナーを登録
     * @returns 
     */
    public setEvenetListener(): DragService
    {
        // this.target.addEventListener('drag', () => {});
        this.target.addEventListener(
            'dragover',
            (e: any) => { this.onDragOver(e) }
        );
        this.target.addEventListener(
            'dragleave',
            (e: any) => { this.onDragLeave(e) }
        );
        /*this.target.addEventListener(
            'drop',
            (e: any) => { this.onDrop(e) }
        );*/

        return this;
    }

    public getFile(): any
    {
        return this.FS.getAllFile();
    }

    public getImage(): any
    {
        return this.FS.getAllImage();
    }

    public getFileToString(): any
    {
        const files: any = this.FS.getAllFile()
        for (const key in files) {
            if (Object.prototype.hasOwnProperty.call(files, key)) {
                files[key]['data'] = btoa(files[key]['data']);
            }
        }
        return files;
    }

    /**
     *
     * @param event ドラッグイベント
     */
    public onDragOver(event: any): DragService
    {
        event.preventDefault();
        this.onDrag = true;
        return this;
    }
    public onDragLeave(event: any): DragService
    {
        event.stopPropagation();
        this.onDrag = false;
        return this;
    }
    /**
     * ファイルドロップイベント
     * @param event ドラッグイベント情報
     * @param next コールバック関数
     * @returns Promise<DragService>
     */
    public async onDrop(event: any, next: any = null): Promise<DragService>
    {
        this.stopScreenEvent(event)

        // イベントからファイル情報を取得
        await this.FS.setFile(event, this.drag, this.binary);

        // this.clearEvent();
        this.startScreenEvent(event);
        event.stopPropagation();

        if (next !== null) {
            next(this.getFile);
        }
        return this;
    }

    /**
     * ブラウザのデフォルトイベントを止める
     * @param e Event
     * @returns 
     */
    public stopScreenEvent(e: any): DragService
    {
        e.preventDefault();
        return this;
    }

    /**
     * ブラウザのデフォルトイベント再開
     * @param e Event
     * @returns 
     */
    public startScreenEvent(e: any): DragService
    {
        e.stopPropagation();
        return this;
    }

    /**
     * タグに指定されたイベントリスナーを止める
     * @returns 
     */
    public clearEvent(): DragService
    {
        //this.target.removeEventListener('drag', () => {});
        this.target.removeEventListener(
            'dragover',
            (e: any) => { this.onDragOver(e) }
        );
        this.target.removeEventListener(
            'dragleave',
            (e: any) => { this.onDragLeave(e) }
        );
        /*this.target.removeEventListener(
            'drop',
            (e: any) => { this.onDrop(e) }
        );*/
         return this;
    }


    private reset(): void
    {
        this.target = null;
        this.binary = true;
        this.drag   = 'drag';
        this.onDrag = false;
        }
}