
export class SvgMakerService {

    /**
     * 印刷サイズ
     * A4を350dpiで出力する場合
     * 横2894px、縦4093px必要
     * 倍率は13.78095倍
     */
    private sheetSize = {
        width: 210,
        height: 297
    };

    /**
     * フォントスタイル対応表
     * ここでフォント定義するのはおかしいので
     * フォント管理を分ける必要あり
     */
    private fonts: Object = {
        meirio      : 'メイリオ',
        ms_gothic   : 'ＭＳ ゴシック',
        ms_mintyou  : 'ＭＳ 明朝',
        kyokasho    : 'UD デジタル 教科書体 NK-R',
        gyousho     : 'HG行書体',
        kaisho      : 'HG正楷書体-PRO',
    }

    private svgTemplate: string = '';
    private images: any = [];
    private texts: any = [];

    private resulution = 13.78095;
    private exportImage: string = '';
    private exportSVG: any = '';

    public setSheetSize(width: number = 0, height: number = 0): SvgMakerService
    {
        this.sheetSize.width = (width > 0) ? width : 210;
        this.sheetSize.height = (height > 0) ? height : 297;
        return this;
    }

    public setResulution(magnification: number): SvgMakerService
    {
        this.resulution = magnification;
        return this;
    }

    public setSvgTemplate(template: string): SvgMakerService
    {
        this.svgTemplate = template;
        return this;
    }

    public setImageData(images: any): SvgMakerService
    {
        this.images = images;
        return this;
    }

    public setTextData(texts: any): SvgMakerService
    {
        this.texts = texts;
        return this;
    }

    public getImage(): string {
        return this.exportImage;
    }

    public getSVG(): string {
        return this.exportSVG;
    }

    /**
     * 印刷画像の作成
     * @returns Promise<string> base64 image at print pdf
     */
    public async sheetMaker(): Promise<string>
    {
        const oc: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D | null = oc.getContext('2d');
        if (ctx === null) {
            return 'false';
        }
        oc.setAttribute('width', this.doEnlargement(this.sheetSize.width));
        oc.setAttribute('height', this.doEnlargement(this.sheetSize.height));

        const svg = new XMLSerializer()
                        .serializeToString(
                            await this.svgBuilder()
                        );
        return new Promise((resolve, reject) => {
            const loadImage = () => {
                const img: HTMLImageElement = new Image();
                img.onload = (e) => {
                    ctx.drawImage(img, 0, 0, Number(this.doEnlargement(this.sheetSize.width)), Number(this.doEnlargement(this.sheetSize.height)));
                    this.exportImage = oc.toDataURL('image/jpg');
                    resolve(this.exportImage);
                }
                img.src = 'data:image/svg+xml;charset=UTF-8,' + svg;
            };
            loadImage();
        })
    }

    public async buildSVGString(): Promise<string>
    {
        const svg = await this.svgBuilder() as SVGSVGElement
        this.exportSVG = svg.innerHTML;
        return this.exportSVG;
    }

    /**
     * svg要素を作成
     * @returns SvgElement
     */
    private async svgBuilder(): Promise<SVGSVGElement>
    {
        const sv = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        sv.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        sv.setAttribute('width', this.doEnlargement(this.sheetSize.width));
        sv.setAttribute('height', this.doEnlargement(this.sheetSize.height));
        sv.setAttribute('viewBox', '0 0 ' + this.doEnlargement(this.sheetSize.width) + ' ' + this.doEnlargement(this.sheetSize.height));
        //const _svg = this.svgTemplate.split('base64,');
        //const hoge = decodeURIComponent(atob(_svg[1]));
        // console.log(hoge);
        //sv.innerHTML = hoge;

        // Template埋め込み
        if (this.svgTemplate !== '') {
            sv.appendChild(this.buildTemplateContent());
        }

        // 画像の埋め込み
        if (this.images !== []) {
            const im = this.buildImageContent();
            for (const key in im) {
                if (Object.prototype.hasOwnProperty.call(im, key)) {
                    sv.appendChild(im[key]);
                }
            }
        }

        // 文字列の埋め込み
        if (this.texts !== []) {
            const txt = this.buildTextContent();
            for (const key in txt) {
                if (Object.prototype.hasOwnProperty.call(txt, key)) {
                    sv.appendChild(txt[key]);
                }
            }
        }
        return sv;
    }

    /**
     * 背景テンプレートを作成
     * @returns SVGImageElement
     */
    private buildTemplateContent(): any
    {
        const tm = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        tm.setAttribute('x', '0');
        tm.setAttribute('y', '0');
        tm.setAttribute('width', this.doEnlargement(this.sheetSize.width));
        tm.setAttribute('height', this.doEnlargement(this.sheetSize.height));
        tm.setAttribute('href', this.svgTemplate);
        return tm;
    }

    /**
     * 文字要素を作成
     * @returns SVGTextElement[]
     */
    private buildTextContent(): any
    {
        if (this.texts.length <= 0) {
            return '';
        }
        const _lists: any = this.texts.map((t, key) => {
            const txt = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            txt.setAttribute('x', this.doEnlargement(t.x));
            txt.setAttribute('y', this.doEnlargement(t.y));
            txt.setAttribute('font-size', this.doEnlargement(t.size));
            txt.setAttribute('font-family', this.fonts[t.font]);
            txt.setAttribute('stroke', 'black');
            txt.setAttribute('text-anchor', 'Super Sans');
            txt.setAttribute('stroke-wWidth', '0.3');
            txt.textContent = t.text;
            return txt;
        });
        return _lists;
    }

    /**
     * 画像要素を作成
     * @returns SVGImageElement[]
     */
    private buildImageContent(): any
    {
        if (this.images[0].image === '') {
            return '';
        }
        const _lists: any = this.images.map((i: any, key) => {
            const im = document.createElementNS('http://www.w3.org/2000/svg', 'image');
            im.setAttribute('x', this.doEnlargement(i.x));
            im.setAttribute('y', this.doEnlargement(i.y));
            im.setAttribute('width', this.doEnlargement(i.width));
            im.setAttribute('height', this.doEnlargement(i.height));
            im.setAttribute('href', i.image);
            return im;
        });
        return _lists;
    }

    /**
     * 拡大処理
     */
    private doEnlargement(val: number): string {
        return (val * this.resulution) + '';
    }
}


