import pdfMake from 'pdfmake/build/pdfmake';
import { StandardSize as ss } from '../layout/standardSize.helper';

/**
 * Example
 * 
 * const printData;
 * const textData;
 * 
 * const printer = new PdfPrinter(printData, textData);
 * printer.print('output_name');
 */



export class PdfPrinter {

    // 用紙の向き（portrait, landscape）
    private sheetOrientation = 'portrait';
    // 用紙規格
    private paperName = 'A4';
    /**
     * 出力用の画像サイズ
     */
     private sheetSize = {
        width: 210,
        height: 297
    };
    // 出力PDF側のmm=>Pixcell変換値
    private sheetSpec = {
        width: 210,
        height: 297
    };

    /**
     * フォントスタイル対応表
     * ここでフォント定義するのはおかしいので
     * フォント管理を分ける必要あり
     */
    private fonts: Object = {
        meirio      : 'メイリオ',
        ms_gothic   : 'ＭＳ ゴシック',
        ms_mintyou  : 'ＭＳ 明朝',
        kyokasho    : 'UD デジタル 教科書体 NK-R',
        gyousho     : 'HG行書体',
        kaisho      : 'HG正楷書体-PRO',
    }

    private svgTemplate: string = '';
    private images: any = [];
    private texts: any = [];

    // private resulution = 13.78095;
    private resulution = 25;
    private exportImage: string = '';

    constructor(printData: any, texts: any) {
        this.sheetSize.width = (printData.width > 0) ? printData.width : 210;
        this.sheetSize.height = (printData.height > 0) ? printData.height : 297;
        this.sheetOrientation = (this.sheetSize.width > this.sheetSize.height) ? 'landscape' : 'portrait';
        this.svgTemplate = printData.svg;
        this.images = printData.images;
        this.paperName = printData.paper;
        this.textConveter(printData.texts, texts);
    }

    public async print(name: string = 'output'): Promise<void>
    {
        await this.sheetMaker();
        this.setSheetStandard();
        this.exportPDF(this.makePdfLayout(), name);
    }

    /**
     * レイアウトの文字列を、置き換えよう文字列に変換
     * @param template 
     * @param texts 
     */
    private textConveter(template: any, texts: any): void
    {
        console.log(template);
        for (const key in template) {
            if (Object.prototype.hasOwnProperty.call(template, key)) {
                if (template[key]['name'] in texts) {
                    this.texts.push({
                        ...template[key],
                        ...{
                            text    : texts[template[key]['name']]
                        }
                    });
                }
            }
        }
        console.log(this.texts);
    }

    /**
     * 印刷用画像データを作成
     * @returns Promise<string>
     */
    private async sheetMaker(): Promise<string>
    {
        const oc: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D | null = oc.getContext('2d');
        if (ctx === null) {
            return 'false';
        }
        oc.setAttribute('width', this.doEnlargement(this.sheetSize.width));
        oc.setAttribute('height', this.doEnlargement(this.sheetSize.height));

        const svg = new XMLSerializer().serializeToString( await this.svgBuilder() );
        return new Promise((resolve, reject) => {
            const loadImage = () => {
                const img: HTMLImageElement = new Image();
                img.onload = (e) => {
                    ctx.drawImage(img, 0, 0, Number(this.doEnlargement(this.sheetSize.width)), Number(this.doEnlargement(this.sheetSize.height)));
                    this.exportImage = oc.toDataURL('image/jpg');
                    resolve(this.exportImage);
                }
                img.src = 'data:image/svg+xml;charset=UTF-8,' + svg;
            };
            loadImage();
        })
    }

    /**
     * svg要素を配置し、印刷用レイアウトを再構成する
     * @returns SvgElement
     */
    private async svgBuilder(): Promise<any>
    {
        const sv = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        sv.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        sv.setAttribute('width', this.doEnlargement(this.sheetSize.width));
        sv.setAttribute('height', this.doEnlargement(this.sheetSize.height));
        sv.setAttribute('viewBox', '0 0 ' + this.doEnlargement(this.sheetSize.width) + ' ' + this.doEnlargement(this.sheetSize.height));

        if (this.svgTemplate !== '') {
            sv.appendChild(this.buildTemplateContent());
        }

        if (this.images !== []) {
            const im = this.buildImageContent();
            for (const key in im) {
                if (Object.prototype.hasOwnProperty.call(im, key)) {
                    sv.appendChild(im[key]);
                }
            }
        }

        if (this.texts !== []) {
            const txt = this.buildTextContent();
            for (const key in txt) {
                if (Object.prototype.hasOwnProperty.call(txt, key)) {
                    sv.appendChild(txt[key]);
                }
            }
        }
        return sv;
    }

    /**
     * 背景テンプレートを作成
     * @returns SVGImageElement
     */
    private buildTemplateContent(): any
    {
        const tm = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        tm.setAttribute('x', '0');
        tm.setAttribute('y', '0');
        tm.setAttribute('width', this.doEnlargement(this.sheetSize.width));
        tm.setAttribute('height', this.doEnlargement(this.sheetSize.height));
        tm.setAttribute('href', this.svgTemplate);
        return tm;
    }

    /**
     * 文字要素を作成
     * @returns SVGTextElement[]
     */
    private buildTextContent(): any
    {
        if (this.texts.length <= 1) {
            return '';
        }
        const _lists: any = this.texts.map((t, key) => {
            const txt = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            txt.setAttribute('x', this.doEnlargement(t.x));
            txt.setAttribute('y', this.doEnlargement(t.y));
            txt.setAttribute('font-size', this.doEnlargement(t.size));
            txt.setAttribute('font-family', this.fonts[t.font]);
            txt.setAttribute('stroke', 'black');
            txt.setAttribute('text-anchor', 'Super Sans');
            txt.setAttribute('stroke-wWidth', '0.3');
            txt.textContent = t.text;
            return txt;
        });
        return _lists;
    }

    /**
     * 画像要素を作成
     * @returns SVGImageElement[]
     */
    private buildImageContent(): any
    {
        if (this.images[0].image === '') {
            return '';
        }
        const _lists: any = this.images.map((i: any, key) => {
            const im = document.createElementNS('http://www.w3.org/2000/svg', 'image');
            im.setAttribute('x', this.doEnlargement(i.x));
            im.setAttribute('y', this.doEnlargement(i.y));
            im.setAttribute('width', this.doEnlargement(i.width));
            im.setAttribute('height', this.doEnlargement(i.height));
            im.setAttribute('href', i.image);
            return im;
        });
        return _lists;
    }

    /**
     * 拡大処理
     */
    private doEnlargement(val: number): string {
        return (val * this.resulution) + '';
    }

    /**
     * 用紙サイズ指定
     * @param name 用紙名（A4, A5, B5,B4 .....etc）
     * @param orientation 向き（縦：portrait, 横: landscape）
     * @returns PrintLayoutService
     */
    private setSheetStandard(): void
    {
        let size = [];
        if (ss.call().check(this.paperName)) {
            size = ss.call().getSize(this.paperName);
        }
        console.log(this.sheetOrientation);
        if (this.sheetOrientation === 'landscape') {
            this.sheetSpec.width = size[1];
            this.sheetSpec.height = size[0];
        } else {
            this.sheetSpec.width = size[0];
            this.sheetSpec.height = size[1];
        }

    }
    /**
     * 印刷レイアウトを作成
     * @returns 
     */
    private makePdfLayout(): any {

        const docDefinition = {
            pageMargins: [0, 0, 0, 0],
            content: [
                {
                    image   : this.exportImage,
                    width   : this.sheetSpec.width,
                    height  : this.sheetSpec.height
                },
            ],
            pageSize: this.paperName,
            pageOrientation: this.sheetOrientation,
            styles: {

            },
            defaultStyle: {
            }
        };
        return docDefinition;
    }

    /**
     * 印刷レイアウトを複数同時作成
     * @param contents string[]
     * @returns 
     */
    private makePdfLayoutMulti(contents: string[]): any {
        const _content: any = [];
        for (const key in contents) {
            if (contents.hasOwnProperty(key)) {
                _content.push({
                    image: contents[key],
                    width: this.sheetSpec.width,
                    height: this.sheetSpec.height,
                    pageBreak: 'after'
                });
            }
        }
        const docDefinition = {
            pageMargins: [0, 0, 0, 0],
            content: [_content],
            styles: {},
            defaultStyle: {}
        };
        return docDefinition;
    }

    /**
     * レイアウト情報からPDF作成
     * ダウンロードイベントを発生させる
     * @param docDefinition any レイアウト情報
     */
    private exportPDF(docDefinition: any, name: string = 'outputpdf') {
        docDefinition.defaultStyle = docDefinition.defaultStyle || {};
        pdfMake.createPdf(docDefinition).download(name + '.pdf');
    }

}

