import { StandardSize as ss } from './standardSize.helper';

export class PrintLayoutService {
    // 用紙サイズ
    private sheetSpec = {width: 210, height: 297};
    // 用紙の向き（portrait, landscape）
    private sheetOrientation = 'portrait';
    // 用紙規格
    private paperName = 'A4';

    /**
     * 用紙サイズ指定
     * @param name 用紙名（A4, A5, B5,B4 .....etc）
     * @param orientation 向き（縦：portrait, 横: landscape）
     * @returns PrintLayoutService
     */
    public setSheetStandard(
        name: string, orientation: string = 'portrait'
    ): PrintLayoutService {
        let size = [];
        if (ss.call().check(name)) {
            this.paperName = name;
            size = ss.call().getSize(name);
        }
        if (orientation === 'landscape') {
            this.setSheetSpec(size[1], size[0]);
            this.sheetOrientation = orientation;
            return this;
        }
        this.setSheetSpec(size[0], size[1]);
        return this;
    }

    public setSheetSpec(width: number, height: number): PrintLayoutService
    {
        this.sheetSpec.width = width;
        this.sheetSpec.height = height;
        return this;
    }

    /**
     * 印刷レイアウトを作成
     * @param image string
     * @returns 
     */
    public makePdfLayout(image: string): any {

        const docDefinition = {
            pageMargins: [0, 0, 0, 0],
            content: [
                {
                    image   : image,
                    width   : this.sheetSpec.width,
                    height  : this.sheetSpec.height
                },
            ],
            pageSize: this.paperName,
            pageOrientation: this.sheetOrientation,
            styles: {

            },
            defaultStyle: {
            }
        };
        return docDefinition;
    }


    /**
     * 印刷レイアウトを複数同時作成
     * @param contents string[]
     * @returns 
     */
    public makePdfLayoutMulti(contents: string[]): any {
        const _content: any = [];
        for (const key in contents) {
            if (contents.hasOwnProperty(key)) {
                _content.push({
                    image: contents[key],
                    width: this.sheetSpec.width,
                    height: this.sheetSpec.height,
                    pageBreak: 'after'
                });
            }
        }
        const docDefinition = {
            pageMargins: [0, 0, 0, 0],
            content: [_content],
            styles: {},
            defaultStyle: {}
        };
        return docDefinition;
    }

    /**
     * 印刷レイアウトを作成
     * @param image string
     * @returns 
     */
     public makePdfLayoutBySVG(svg: string): any {

        const docDefinition = {
            pageMargins: [0, 0, 0, 0],
            content: [
                {
                    svg     : svg,
                    width   : this.sheetSpec.width,
                    height  : this.sheetSpec.height
                },
            ],
            pageSize: this.paperName,
            pageOrientation: this.sheetOrientation,
            styles: {

            },
            defaultStyle: {
            }
        };
        return docDefinition;
    }
}
