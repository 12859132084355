/**
 * ResponceHeaders
 *  'Cache-Control' : string;
 *  'Connection'    : string;
 *  'Content-Type'  : string;
 *  'Date'          : string;
 *  'Keep-Alive'    : any;
 *  'Server'        : string;
 *  'Set-Cookie'    : string;
 */
export interface ResponceHeadersInterface
{
    'Cache-Control' : string;
    'Connection'    : string;
    'Content-Type'  : string;
    'Date'          : string;
    'Keep-Alive'    : any;
    'Server'        : string;
    'Set-Cookie'    : string;
    [key: string]: any;
}

export const ResponceHeaderInitial = {
    'Cache-Control' : '',
    'Connection'    : '',
    'Content-Type'  : '',
    'Date'          : '',
    'Keep-Alive'    : {},
    'Server'        : '',
    'Set-Cookie'    : '',
};

/**
 * レスポンスヘッダー情報を管理
 */
export default class ResponceHeaderService
{
    private headers: ResponceHeadersInterface = ResponceHeaderInitial;

    /**
     * ヘッダー情報を設定
     * ResponceHeaderInterfaceに含まれないデータは無視
     * 
     * @param header any
     * @return this
     */
    public setHeaders(header: any): this
    {
        for (const key in this.headers) {
            if (Object.prototype.hasOwnProperty.call(this.headers, key)) {
                this.headers[key] = header[key];
            }
        }
        return this;
    }

    /**
     * 全てのヘッダー情報を返す
     * 
     * @return ResponceHeadersInterface
     */
    public getAllHeaders(): ResponceHeadersInterface
    {
        return this.headers;
    }

    /**
     * ヘッダーの一致するKey情報を返す
     * ResponceHeadersInterfaceに含まれるデータのみ
     * 
     * @param key string
     * @return object
     */
    public getHeader(key: string): object
    {
        if (key in this.headers) {
            return {key: this.headers[key]};
        }
        return {};
    }

    /**
     * ヘッダーにKeyが含まれるか判定
     * 
     * @param key string
     * @param word string
     * @return boolean
     */
    public checkHeader(key: string, word: string): boolean
    {
        if (key in this.headers) {
            return (this.headers[key] === word) ? true : false;
        }

        return false;
    }

    /**
     * hedersをイニシャルステートで埋める
     * 
     * @return this
     */
    public reset(): this
    {
        this.headers = ResponceHeaderInitial;
        return this;
    }
}