
export class ExportHelper
{
    private static instance: ExportHelper;

    private exportData  : any;
    private extention   : string = 'jpeg';
    private encode      : string = 'utf-8';

    private multibyte   : boolean = false;

    public static call(): ExportHelper
    {
        if (!ExportHelper.instance) {
            ExportHelper.instance = new ExportHelper();
        }
        return ExportHelper.instance;
    }

    /**
     * 出力データを設定
     * @param data string | binary 文字列、バイナリ、配列...
     * @returns ExportHelper
     */
    public setData(data: any): ExportHelper
    {
        if (data.match(/^[\x20-\x7E]+$/)) {
            this.multibyte = true;
        } else {
            this.multibyte = false;
        }
        this.exportData = data;
        return this;
    }

    /**
     * 出力する拡張子を設定
     * 出力時のヘッダー上方となるため通常は設定したデータの拡張子を渡す
     * @param extention string 拡張子
     * @returns ExportHelper
     */
    public setExtension(extention: string): ExportHelper
    {
        this.extention = (extention in this.mimeList) ? extention : 'txt';
        return this;
    }

    /**
     * downloadイベントを発生させる
     * @param name string ファイル名（デフォルト：export）
     */
    public download(name: string = 'export'): any
    {
        const blob = new Blob([this.exportData], {type: this.mimeList[this.extention]});
        const url = window.URL.createObjectURL(blob);
        const _a = document.createElement('a');
        _a.href = url;
        _a.download = name + '.' + this.extention;
        _a.click();
        _a.remove();
    }

    /**
     * base64文字列を返す
     * @returns string base64
     */
    public base64(): string
    {
        const header = 'data:' + this.mimeList[this.extention] + ';base64,';
        if (this.multibyte) {
            return header + btoa(encodeURIComponent(this.exportData));
        }
        return header + btoa(this.exportData);
    }

    private mimeList = {
        'json'  : 'application/json',
        'gif'   : 'image/gif',
        'jpeg'  : 'image/jpeg',
        'jpg'   : 'image/jpeg',
        'png'   : 'image/png',
        'svg'   : 'image/svg/xml',
        'pdf'   : 'application/pdf',
        'txt'   : 'text/plain',
        'csv'   : 'text/csv;charset=utf=8',
        'doc'   : 'application/msword',
        'docx'  : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'xls'   : 'application/vnd.ms-excel',
        'xlsx'  : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'xml'   : 'text/xml',   // 複数形式あり
    }
}