
export interface ResponceInterface {
    type: string;
    message: any;
}

export const ResponceInitial = {
    type    : '',
    message : {},
}

/**
 * レスポンスのBodyデータを管理
 * 
 * Bodyデータ自体がストリームデータになる
 * 別処理を間に挟むとデータ自体が取得できなくなるため
 * オーバーライドする場合はデータの取得順に注意
 */
export default class ResponceBodyService
{

    private body: any = null;
    private _body: ResponceInterface = ResponceInitial;

    /**
     * Bodyデータを設定
     * 
     * @param body any
     * @return Promise<ResponceBodyService>
     */
    public async setBody(body: any): Promise<ResponceBodyService>
    {
        // 特定のメソッド通信の場合Bodyが空になるので
        // Jsonエンコードエラーを拾って処理する
        try {
            await body.clone().json().then((res: any) => {
                this.body = res
            });
        } catch (error) {
        // 
            const reader = await body.body.getReader();
            const res = await reader.read();
            console.error(error);
            this.body = (new TextDecoder).decode(Uint8Array.from(res.value));
        }
        return this;
    }

    /**
     * 未編集のBODYデータを返す
     */
    public getAllBody(): any
    {
        return this.body;
    }

    /**
     * パース処理済みのBodyデータを返す
     */
    public getParseBody(): ResponceInterface
    {
        return this._body;
    }

    public parse(job: string): ResponceBodyService
    {
        if (this.body === null) return this;
        if (job === 'normal') this.buildNormalBody();
        if (job === 'token') this.buildTokenBody();
        if (job === 'success') this.buildSuccessBody();
        if (job === 'error') this.buildErrorBody();
        return this;
    }

    public reset(): ResponceBodyService
    {
        this.body = null;
        this._body = ResponceInitial;
        return this;
    }

    private buildNormalBody(): ResponceBodyService
    {
        if (this.body.statusText === 'OK'
            || this.body.status === 200) {
            this._body.type = 'success';
        } else {
            this._body.type = 'error';
        }
        this._body.message = this.body;
        return this;
    }

    private buildTokenBody(): ResponceBodyService
    {
        this._body.type = 'success';
        this._body.message = this.body;
        return this;
    }

    private buildSuccessBody(): ResponceBodyService
    {
        this._body.type = 'success';
        this._body.message = this.body;
        return this;
    }

    private buildErrorBody(): ResponceBodyService
    {
        console.log("aaa");
        this._body.type = 'error';
        this._body.message = this.body;
        return this;
    }

}