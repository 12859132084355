import * as Excel from 'exceljs';

export class SpreadSheetService
{
    private Sheet       : any;          // 読み込むエクセルデータ

    private book        : any = null;   // エクセル
    private workbook    : any = null;   // エクセルファイルを開いたデータ
    private sheets      : any = null;   // エクセルのシート
    private cellMap     : string[] = [
        '','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
        'AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL','AM','AN','AO','AP','AQ','AR','AS','AT','AU','AV','AW','AX','AY','AZ',
        'BA','BB','BC','BD','BE','BF','BG','BH','BI','BJ','BK','BL','BM','BN','BO','BP','BQ','BR','BS','BT','BU','BV','BW','BX','BY','BZ',
        'CA','CB','CC','CD','CE','CF','CG','CH','CI','CJ','CK','CL','CM','CN','CO','CP','CQ','CR','CS','CT','CU','CV','CW','CX','CY','CZ',
        'DA','DB','DC','DD','DE','DF','DG','DH','DI','DJ','DK','DL','DM','DN','DO','DP','DQ','DR','DS','DT','DU','DV','DW','DX','DY','DZ',
    ];
    private sheetPropaties: string[] = [];
    private dataMap     : Object = {
        name_ruby   : { text: 'うんこまん', size: 0 },
        name        : { text: 'うんこまん', size: 0 },
        address_ruby: { text: 'うんこ星雲うんこ星', size: 0 },
        post_code   : { text: '123-4567', size: 0 }
    }
    private imageMap    : Object = {
        target      : {
            img         : '',
            width       : 0,
            height      : 0,
        },
    }

    /**
     * エクセルシートデータを設定
     * @param sheet string base64変換されたエクセルシート
     * @returns SpreadSheetService
     */
    public setSpreadSheet(sheet: string): SpreadSheetService
    {
        const _sheet: string[] = sheet.split('base64,');
        this.Sheet = new Uint8Array(
                        this.stringToBuffer(atob(_sheet[1]))
                    );
        return this;
    }

    /**
     * 変数置き換え用の文字列連想配列
     * @param data Object 変数と置き換える文字列の連想配列
     * @returns SpreadSheetService
     */
    public setDataMap(data: Object): SpreadSheetService
    {
        this.dataMap = data;
        return this;
    }

    public setImageMap(image: Object): SpreadSheetService
    {
        this.imageMap = image;
        return this;
    }

    /**
     * エクセルシートをダウンロード
     * @param file string ファイル名（拡張子無しで指定）
     * @example hoge.xlsx => file = 'hoge'
     * @return Promise<void>
     */
    public async exportSpreadSheet(file: string = 'export'): Promise<void>
    {
        const uint8Array = await this.workbook.xlsx.writeBuffer();
        const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
        const url = window.URL.createObjectURL(blob);
        const _a = document.createElement('a');
        _a.href = url;
        _a.download = file + '.xlsx';
        _a.click();
        _a.remove();
    }

    /**
     * シートに設定された変数名一覧を返す
     * @returns string[] 変数名一覧
     */
    public getSheetPropatie(): string[]
    {
        return this.sheetPropaties;
    }

    /**
     * 変数が設定されたセルの変数名を全て抽出
     * @returns SpreadSheetService
     */
    public async mappingSheet(): Promise<SpreadSheetService>
    {
        await this.openBook();
        
        this.cellScan((c, r, _v) => {
            this.sheetPropaties.push(_v);
        });
        this.sheetPropaties = Array.from(new Set(this.sheetPropaties));
        return this;
    }

    /**
     * 変数が設定されたセルを文字列に置き換える
     * @returns SpreadSheetService
     */
    public async mappingDataset(): Promise<SpreadSheetService>
    {
        await this.openBook();
        
        this.cellScan((c, r, _v) => {
            if (this.dataMap[_v] === 'image') {
                this.sheets.addImage(this.mappingImage(_v), {
                    tl  : { col: c, row: r },
                    ext : { width: this.imageMap[_v]['width'], height:  this.imageMap[_v]['height'] }
                });
            } else {
                this.sheets.getCell(String(this.cellMap[c] + r)).value = this.dataMap[_v];
            }

            /*this.sheets.getCell(String(this.cellMap[c] + r)).font = {
                size: 20,
            };*/
            //this.addRows(c,r,_v);
        });
        return this;
    }

    public addRows(c, r, _v): void
    {
        
        const rows: number[]|string[] = [];
        rows[2] = 10;
        rows[3] = 10;
        rows[4] = 'うんこ';
        const row = this.sheets.duplicateRow(22, 23, false);
        this.sheets.insertRow(23, rows);
        /*
        const a = this.sheets.getCell(String(this.cellMap[c] + r)).fill;
        console.log(a);
        const b = this.sheets.getCell(String(this.cellMap[c] + r)).border;
        console.log(b);
        const _c = this.sheets.getCell(String(this.cellMap[c] + r)).font;
        console.log(_c);
        const d = this.sheets.getCell(String(this.cellMap[c] + r)).alignment;
        console.log(d);*/
    }

    private mappingImage(image: string): any
    {
        return this.workbook.addImage({
            base64  : this.imageMap[image]['img'],
            extension : this.imageMap[image]['type'],
        })
    }

    /**
     * Excellのブックを開く
     * @param renew boolean 開き直しフラグ
     */
    private async openBook(renew: boolean = false): Promise<any>
    {
        if (this.book === null || renew) {
            this.book = new Excel.Workbook();
            this.workbook = await this.book.xlsx.load(this.Sheet);
            this.sheets = this.workbook.worksheets[0];
        }
    }

    /**
     * 有効なセルを全て検索
     * @param next Function 変数が設定されたセルに対する処理
     */
    private cellScan(next: Function): void
    {
        // const col = this.sheets.actualColumnCount;
        // const row = this.sheets.actualRowCount;
        const col = 100;
        const row = 100;
        for (let c = 1; c <= col; c++) {
            for (let r = 1; r <= row; r++) {
                const v = String(this.sheets.getCell(String(this.cellMap[c] + r)).value);
                if (v.search('^[\$\[].+\]') > -1) {
                    const _v = v.replace('$[', '').replace(']', '');
                    next(c, r, _v);
                }
            }
        }
    }

    /**
     * 文字列をArrayBuffer（Uint8Array）に変換する
     * @param src string
     * @returns ArrayBuffer
     */
    private stringToBuffer(src: string): ArrayBuffer {
        let ar: any = [];
        return (new Uint8Array(ar.map.call(src, (c: string) => {
          return c.charCodeAt(0)
        }))).buffer;
    }

}