
export class StandardMakerService {

    /**
     * 印刷サイズ
     * はがきを350dpiで出力する場合
     * 横1378px、縦2040px必要
     * 倍率は13.78095倍
     */
    private sheetSize = {
        width: 210,
        height: 297
    };

    private printImage = '';

    private resulution = 13.78095;
    private prevewImage;

    setSheetSize(width: number = 0, height: number = 0): StandardMakerService {
        this.sheetSize.width = (width > 0) ? width : 210;
        this.sheetSize.height = (height > 0) ? height : 297;
        return this;
    }

    public setPrintImage(image: string): StandardMakerService {
        this.printImage = image;
        return this;
    }
    setResulution(magnification: number): StandardMakerService {
        this.resulution = magnification;
        return this;
    }

    public async sheetMaker(): Promise<string> {

        this.doEnlargement();

        const oc: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D | null = oc.getContext('2d');
        if (ctx === null) {
            return 'false';
        }
        oc.setAttribute('width', (this.sheetSize.width).toString());
        oc.setAttribute('height', (this.sheetSize.height).toString());

        return new Promise((resolve, reject) => {

            const loadImage = () => {
                const img: HTMLImageElement = new Image();
                img.onload = (e) => {
                    ctx.drawImage(img, 0, 0, this.sheetSize.width, this.sheetSize.height);
                    this.prevewImage = oc.toDataURL('image/jpg');
                    resolve(this.prevewImage);
                }
                img.src = this.printImage;
            };
            loadImage();
        });

    }

    /**
     * 指定倍率の応じたシートサイズの拡大
     */
    doEnlargement(): void {
        this.sheetSize.width = this.sheetSize.width * this.resulution;
        this.sheetSize.height = this.sheetSize.height * this.resulution;
    }

    getPreviewImage(): string {
        return this.prevewImage;
    }

    initialization(): void {
        this.sheetSize = {
            width: 210,
            height: 297
        };
        this.resulution = 1;

        this.prevewImage = null;
    }

}


