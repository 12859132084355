import AjaxParseService from './ajax.parse.service';
import RequestService from './request.service';
export default class AjaxService
{
    private Request: RequestService = new RequestService();
    private APS: AjaxParseService = new AjaxParseService();
    private result: any = {};

    /**
     * URLを設定
     * 
     * @param url string
     */
    public setURL(url: string): AjaxService
    {
        this.Request.setURL(url);
        return this;
    }

    /**
     * メソッドを設定
     * 
     * @param method string
     */
    public setMethod(method: string): AjaxService
    {
        this.Request.setMethod(method);
        return this;
    }

    /**
     * CSRFトークンを設定
     * 
     * @param token string
     * @return AjaxService
     */
    public setCSRFToken(token: string): AjaxService
    {
        this.Request.setToken('csrf', token);
        return this;
    }

    /**
     * Bearerトークンを設定
     * 
     * @param token string
     * @returns AjaxService
     */
     public setBearerToken(token: string): AjaxService
     {
         this.Request.setToken('bearer', token);
         return this;
     }

    /**
     * ヘッダーを設定
     * 
     * @param header string
     */
    public setHeader(header: string): AjaxService
    {
        this.Request.setHeader(header);
        return this;
    }

    /**
     * 送信データを設定
     * 
     * @param body any
     */
    public setBody(body: any): AjaxService
    {
        this.Request.setBody(body);
        return this;
    }

    /**
     * ヘッダー、送信データをHttpオプション形式に変換
     */
    public buildRequestParam(): AjaxService
    {
        this.Request.buildRequestParam();
        return this;
    }

    /**
     * 登録中のパラメータの取得
     * 
     * @return object {
     *  url: string,
     *  method: string,
     *  header: string,
     *  body: string,
     * }
     */
    public getParam(): object
    {
        return this.Request.getAllRequest();
    }

    /**
     * 送信用に変換したパラメータの取得
     * 
     * @return object {
     *  bodyByJson      : jsonString,
     *  headerByHeader  : object
     * }
     */
    public getBuildParam(): object
    {
        return {
            body      : this.Request.getBuildBody(),
            headers   : this.Request.getBuildHeader(),
        };
    }

    /**
     * 通信結果の受け取り
     */
    public async getResult(): Promise<any>
    {
        await this.run();
        return this.result;
    }

    /**
     * AjaxParseServiceObjectを返す
     * 
     * @return AjaxParseService
     */
    public callAjaxParseService(): AjaxParseService
    {
        return this.APS;
    }

    /**
     * サーバーレスポンスを初期化
     * 
     * @return this
     */
    public resetResult(): AjaxService
    {
        this.APS.allReset();
        return this;
    }

    /**
     * サーバーリクエストを初期化
     * 
     * @return this
     */
    public resetRequest(): AjaxService
    {
        this.Request.reset();
        return this;
    }

    /**
     * サーバーにリクエストを投げる
     * 
     * @return Promise<any>
     */
    private async run(): Promise<any>
    {
        const url = this.Request.buildURL();
        // console.log(this.Request.buildOption());
        return await fetch(
            url,
            this.Request.buildOption()
        )
        .then( async res => {
            console.log(res);
            await this.APS.setResult(res);
            this.result = this.APS.check().getBody();
        })
        .catch(async e => {
            console.error(e);
            await this.APS.setResult(e);
            this.result = this.APS.check().getBody();
        });
    }

}

