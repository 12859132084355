export interface FileTypes {
    data: any,
    type: string,
    name: string,
    size: number,
}
export interface ImageTypes {
    image: any,
    type: string,
    name: string,
    size: number,
}

export class FileService {

    // 画像ファイルリスト
    private images: ImageTypes[] = [];
    // ファイルリスト
    private files: FileTypes[] = [];
    // 送信用ファイルの一時保存配列
    private sendStack = [];
    // ファイルAPI
    private Reader: any = {};
    // ファイルサイズリミット　byte表記
    // private sizeLimit = 5000000;
    private sizeLimit = 250000000;

    /*private iconList = {
        'zip': 'zip',
        'exe': 'pc',
        'pdf': 'pdf',
        'txt': 'txt',
        'xlsx': 'excell',
        'xls': 'excell',
        'docx': 'word',
        'doc': 'word',
    };*/

    /**
     * イベントからファイル情報を登録
     * 
     * @param e any ドラッグ・selectイベント
     * @param event string [drag, select]イベント種別
     * @returns boolean
     */
    public async setFile(e: any, event: string = 'drag', binary: boolean = true): Promise<boolean> {
        let file: any = {};
        if (event === 'drag') {
            file = e.dataTransfer.files
        }
        if (event === 'select') {
            file = e.target.files
        }
        return await this.Analysis(file, binary);
    }

    /**
     * ファイルデータを配列に追加
     * 
     * @param file any ファイルデータ（base64化されている場合多し）
     * @param type string ファイルタイプ
     * @param name string 取得時のファイル名
     * @param size number ファイル容量
     */
    public addFile(file: any, type: string, name: string, size: number): void {
        const _file: FileTypes = {
            data: file,
            type: type,
            name: name,
            size: size
        };
        this.files = {...this.files, ..._file};
    }

    /**
     * 画像データを追加
     * @param image any 画像データ（大体base64文字列）
     * @param type stirng 拡張子
     * @param name string ファイル名
     * @param size number サイズ
     */
    public addImage(
        image: any, type: string, name: string, size: number
    ): void {
        const _file: FileTypes = {
            data: image,
            type: type,
            name: name,
            size: size
        };
        this.images = {...this.images, ..._file};
    }

    /**
     * 画像以外のファイルを全て取得
     * @returns FileTypes[]
     */
    public getAllFile(): FileTypes[] {
        return this.files;
    }

    /**
     * index番号からファイルを取得（画像は取得できない）
     * @param index number 配列番号
     * @returns FileTypes
     */
    public getFileByIndex(index: number): FileTypes {
        const file = this.files.filter((n, i, a) => {
            if (i === index) {
                return n;
            }
            return false;
        });
        return file[0];
    }

    /**
     * 拡張子からファイルを取得（画像は取得できない）
     * @param type string 拡張子
     * @returns FileTypes[]
     */
    public getFileByType(type: string): FileTypes[] {
        const files = this.files.filter((n: any) => {
            if (n.type === type) {
                return n;
            }
            return false;
        });
        return files;
    }

    /**
     * 画像データを全て取得
     * @returns ImageTypes[]
     */
    public getAllImage(): ImageTypes[] {
        return this.images;
    }

    /**
     * Index番号から画像データを取得
     * @param index number Index番号 
     * @returns ImageTypes
     */
    public getImageByIndex(index: number): ImageTypes {
        const image = this.images.filter((n, i, a) => {
            if (i === index) {
                return n;
            }
            return false;
        });
        return image[0];
    }

    /**
     * 拡張子から画像データを取得
     * @param type string 拡張子
     * @returns ImageTypes[]
     */
    public getImageByType(type: string): ImageTypes[] {
        const image = this.images.filter((n: any) => {
            if (n.type === type) {
                return n;
            }
            return false;
        });
        return image;
    }


    /**
     * ファイルタイプを調べる
     * @param files 
     * @returns Promise<boolean>
     */
    private Analysis(files: any, binary: boolean): Promise<boolean>
    {
        const count = files.length;
        return new Promise((resolve) => {
            let counter = 0;
            const chCounter = () => {
                counter++;
                if (count === counter) {
                    resolve(true);
                }
            };

            for (const key in files) {
                if (!files.hasOwnProperty(key)) continue;
                if (!this.checkFileSize(files[key])) continue;
                if (binary) {
                    this.ReadFile(files[key]).then(() => chCounter());
                } else {
                    this.ReadTextFile(files[key]).then(() => chCounter());
                }
            }
        });
    }

    /**
     * ファイルを読み込み
     * @param file 
     * @returns 
     */
    private async ReadFile(file: any): Promise<boolean> {
        this.Reader = new FileReader();
        return new Promise((resolve) => {
            try {
                this.Reader.onloadend = (event: any) => {
                    const type = this.checkFileType(file);
                    const _file = event.target.result;
                    if (this.checkImage(type)) {
                        this.addImage(_file, type, file.name, file.size);
                    } else {
                        this.addFile(_file, type, file.name, file.size);
                    }
                    resolve(true);
                };
                this.Reader.readAsDataURL(file);
            } catch (error) {
                console.log('File Read ERROR ::' + error);
                resolve(false);
            }
        });
    }

    private async ReadTextFile(file: any): Promise<boolean>
    {
        this.Reader = new FileReader();
        return new Promise((resolve) => {
            try {
                const result: any = [];
                this.Reader.onloadend = (e) => {
                    const body = this.Reader.result.toString().split('\n');
                    for (let j = 0; j < body.length; j++) {
                        result[j] = body[j].split(',');
                    }
                    const jb = JSON.stringify(result);
                    this.addFile(JSON.parse(jb), 'csv', file.name, file.size);
                    resolve(true);
                };
                this.Reader.readAsText(file, 'UTF-8');
            } catch (error) {
                console.log('File Read ERROR ::' + error);
                resolve(false);
            }
        });
    }

    /**
     * 画像ファイルか判定
     * @param type 
     * @returns 
     */
    private checkImage(type: string): boolean {
        if (type === 'jpeg' || type === 'gif' || type === 'png') {
            return true;
        } else {
            return false;
        }
    }

    /**
     * 拡張子を調べる
     * @param file 
     * @returns 
     */
    private checkFileType(file: any): string {
        
        if (file.type.indexOf('jpeg') > 0) {
            return 'jpeg';
        } else if (file.type.indexOf('gif') > 0) {
            return 'gif';
        } else if (file.type.indexOf('png') > 0) {
            return 'png';
        } else if (file.type.indexOf('x-zip-compressed') > 0) {
            return 'zip';
        } else if (file.type.indexOf('x-msdownload') > 0) {
            return 'exe';
        } else if (file.type.indexOf('pdf') > 0) {
            return 'pdf';
        } else if (file.type.indexOf('text') > 0) {
            return 'txt';
        } else if (file.type.indexOf('spreadsheetml') > 0) {
            return 'xlsx';
        } else if (file.type.indexOf('vnd.ms-excel') > 0) {
            return 'xls';
        } else if (file.type.indexOf('wordprocessingml') > 0) {
            return 'docx';
        } else if (file.type.indexOf('msword') > 0) {
            return 'doc';
        } else if (file.type.indexOf('svg') > 0) {
            return 'svg';
        } else {
            return 'file';
        }
    }

    /**
     * ファイルサイズがサイズ制限以下であることを確認
     * 0～制限サイズまで ： True
     * 制限サイズ以上　　： False
     * 
     * @param file FileObject
     * @returns boolean
     */
    private checkFileSize(file: any): boolean {

        if (this.sizeLimit === 0
            || file.size <= this.sizeLimit) {
            return true;
        }

        return false;
    }
}
