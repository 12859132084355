
export interface ResponceInterface
{
    status      : number;   // 200 500 等のステータスコード
    method      : string;   // GET POST ...etc
    ok          : boolean;  // 通信結果のbool
    redirected  : boolean;  // リダイレクトされているか
    statusText  : string;   // 「OK、304 Not Modified」等のメッセージ情報
    type        : string;   // json text 等の戻りデータの型
    url         : string;   // リクエスを投げたURL
    [key: string]: string | number | boolean;
}

export const ResponceInitial = {
    status      : 0,
    method      : '',
    ok          : true,
    redirected  : true,
    statusText  : '',
    type        : '',
    url         : '',
}

/**
 * サーバーレスポンスの管理サービス
 * 
 * body header部分は含まない
 * 
 */
export default class ResponceService
{
    private responce: ResponceInterface = ResponceInitial;

    /**
     * サーバーレスポンスを設定
     * Body、headerは受け取らない
     * 
     * @param responce any
     * @return this
     */
    public setResponce(responce: any): this
    {
        for (const key in this.responce) {
            if (Object.prototype.hasOwnProperty.call(this.responce, key)) {
                this.responce[key] = responce[key];
            }
        }
        return this;
    }

    /**
     * レスポンスデータを全て返す
     * 
     * @return ResponceInterface
     */
    public getAllResponce(): ResponceInterface
    {
        return this.responce;
    }

    /**
     * レスポンスデータをイニシャルデータで埋める
     * 
     * @return this
     */
    public reset(): this
    {
        this.responce = ResponceInitial;
        return this;
    }

}