import { ExportHelper } from '../_helper/export.helper';

export class CsvService
{
    private exportData   : any;

    /**
     * CSV変換データ登録
     * @param data any[] | object
     * @returns CsvService
     */
    public setData(data: any[] | object): CsvService
    {
        let _data: any[];
        _data = (!Array.isArray(data)) 
            ? this.convObjectToCSV(data) : data;
        
        this.convCSV(_data);
        console.log(this.exportData);
        return this;
    }

    /**
     * CSVファイルを返す
     * @returns any CSVファイルデータ
     */
    public getCSV(): any
    {
        return this.exportData;
    }

    /**
     * エクセルシートをダウンロード
     * @return CsvService
     */
    public exportCSV(file: string = 'export'): void
    {
        ExportHelper.call()
            .setData(this.exportData)
            .setExtension('csv')
            .download(file);
    }

    private convCSV(data: any[]): void
    {
        let csv_string = '';
        for (let i = 0; i < data.length; i++) {
            csv_string += data[i];
            csv_string += '\r\n';
        }

        // BOM追加
        // const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        // csv_string = '\ufffe' + csv_string;

        // ファイル作成
        /*this.exportCsv = new Blob([bom, csv_string] , {
            type: 'text/csv;charset=utf-8;'
        });*/
        this.exportData = csv_string;
    }

    /**
     * オブジェクトを配列に変換
     * @param content object
     * @returns any[]
     */
    private convObjectToCSV(content: any): any {
        console.log(content);
        if (typeof content === 'string') {
            return content;
        }
        if (typeof content === 'number') {
            return content;
        }
        if (Array.isArray(content)) {
            if (content.length === 0) {
                return '';
            }
            return content.toString();
        }

        if (Object.keys(content).length === 0) {
            return '';
        }
        const convData: any[] = [];
        for (const key in content) {
            if (content.hasOwnProperty(key)) {
                const element = key + ',' + this.convObjectToCSV(content[key]);
                
                convData.push(element);
            }
        }
        return convData;
    }

}