import 
    ResponceHeaderService,
    { ResponceHeadersInterface }
from './responce.header.service';
import
    ResponceService,
    { ResponceInterface }
 from './responce.service';
import ResponceBodyService from './responce.body.service';

/**
 * 非同期通信、APIの戻り値を固定形式に変換する
 */
export default class AjaxParseService
{
    private result: any = {};
    private Headers = new ResponceHeaderService();
    private Responce = new ResponceService();
    private ResponceBody = new ResponceBodyService();

    /**
     * APIからの戻りデータを受け取る
     * HTTPステータスコードに依存しない
     * 
     * @param result json object
     * @return AjaxParseHelper
     */
    public async setResult(result: any): Promise<AjaxParseService>
    {
        this.result = result;
        this.Headers.setHeaders(this.result.headers);
        this.Responce.setResponce(this.result);
        await this.ResponceBody.setBody(this.result);
        // console.log(this.ResponceBody.getAllBody());
        return this;
    }

    /**
     * サーバーレスポンスのBody要素を返す
     * 
     * @return object
     */
    public getBody(): object
    {
        return this.ResponceBody.getParseBody();
    }

    /**
     * サーバーレスポンスのヘッダー要素を返す
     * 
     * @return ResponceHeadersInterface
     */
    public getHeader(): ResponceHeadersInterface
    {
        return this.Headers.getAllHeaders();
    }

    /**
     * サーバーレスポンスのプロパティを返す
     * 
     * @return ResponceInterface
     */
    public getResponce(): ResponceInterface
    {
        return this.Responce.getAllResponce();
    }

    /**
     * ResponseHeaderServiceを返す
     * 
     * @return ResponseHeaderService
     */
    public callHeaderObj(): ResponceHeaderService
    {
        return this.Headers;
    }

    /**
     * ResponceServiceを返す
     * 
     * @return ResponceService
     */
    public callResponceObj(): ResponceService
    {
        return this.Responce;
    }

    /**
     * ResponceBodyServiceを返す
     * 
     * @return ResponcebodyService
     */
    public callBodyObj(): ResponceBodyService
    {
        return this.ResponceBody;
    }

    /**
     * Resultデータのチェック開始
     * @return AjaxParseHelper
     */
    public check(): this
    {
        if ('status' in this.result) {
            this.ResponceBody.parse('normal');
        } else {
            if ('access_token' in this.result) {
                this.ResponceBody.parse('token');
            } else {
                this.ResponceBody.parse('success');
            }
        }
        return this;
    }

    /**
     * Responseデータを全てイニシャルデータで埋める
     * 
     * @return this
     */
    public allReset(): this
    {
        this.Responce.reset();
        this.ResponceBody.reset();
        this.Headers.reset();
        return this;
    }

}